import React from "react"
import styled from "styled-components"
import { Nav, NavDropdown } from "react-bootstrap"
import { menuData } from "../../data/MenuData"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <DropdownContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <DropdownMenu>
          <Nav defaultActiveKey="/home" className="flex-column">
            {menuData.map((item, index) => (
              <NavDropdown title={item.title} id={item.id} key={item.id}>
                {item.links.map((item, index) => (
                  <NavDropdown.Item href={item.link} key={item.id}>
                    {item.title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}
            <Nav.Item>
              <Nav.Link
                eventKey="99"
                href="https://www.contentcreator.com/login"
              >
                Login
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </DropdownMenu>
      </DropdownWrapper>
    </DropdownContainer>
  )
}

export default Dropdown

const DropdownContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  /* background: red; */
`

const Icon = styled.div`
  position: absolute;
  top: 5rem;
  right: 5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

const CloseIcon = styled(FaTimes)`
  color: #fff;
`

const DropdownWrapper = styled.div`
  color: #fff;
  height: 50%;
`
const DropdownMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 120%;
  font-size: 2rem;
`
// const DropdownLink = styled(Link)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 1.5rem;
//   text-decoration: none;
//   list-style: none;
//   color: #fff;
//   cursor: pointer;
//   transition: 0.2s ease-in-out;

//   &:hover {
//     color: #f26a2e;
//   }
// `

// const BtnWrap = styled.div`
//   display: flex;
//   justify-content: center;
// `
